import React, { useEffect, useState } from 'react';
import RulesComponentHolder from "./Styles/rulesComponentStyle";
import { useTranslation } from 'react-i18next';
import { Tooltip, Button, Icon } from 'antd';

const ListRulesComponent = (props) => {

    const { t } = useTranslation();
    const { showModalRules } = props;
    const { listContentDisplay, styleRules, defaultText, type } = props;
    const [rulesAppliedInContentDisplay, setRulesAppliedInContentDisplay] = useState([]);
    const [existRulesInContentDisplay, setExistRulesInContentDisplay] = useState(false);

    const getRulesAppliedInContentDisplay = () => {
        const repeatedRules = [];
        let hasAnyRuleApplied = false;
        let existRules = false;

        if (listContentDisplay.length === 0) {
            return repeatedRules;
        }

        const firstContentDisplay = listContentDisplay[0];
        const rulesOfFirstContentDisplay = firstContentDisplay.rules || [];

        if (rulesOfFirstContentDisplay.length === 0) {
            hasAnyRuleApplied = listContentDisplay.some(cd => cd.rules && cd.rules.length > 0);
            if (hasAnyRuleApplied) existRules = true;
            setExistRulesInContentDisplay(existRules);
            return repeatedRules;
        }

        rulesOfFirstContentDisplay.forEach(rule => {
            const isRepeated = listContentDisplay.every(contentDisplay =>
                contentDisplay.rules && contentDisplay.rules.some(r => r.configRules.id === rule.configRules.id)
            );

            if (isRepeated) {
                repeatedRules.push(rule);
            } else {
                hasAnyRuleApplied = true;
                existRules = true
            }
        });

        const allFirstRuleIds = new Set(rulesOfFirstContentDisplay.map(r => r.configRules.id));
        const hasExtraRules = listContentDisplay.some(cd =>
            cd.rules && cd.rules.some(rule => !allFirstRuleIds.has(rule.configRules.id))
        );

        if (hasExtraRules) hasAnyRuleApplied = true;
        if (existRules) setExistRulesInContentDisplay(existRules);

        return repeatedRules;
    };

    useEffect(() => {
        let listRules = getRulesAppliedInContentDisplay();
        setRulesAppliedInContentDisplay(listRules);
    }, [ listContentDisplay]);

    const maxVisibleRules = 5;
    const visibleRules = rulesAppliedInContentDisplay.slice(0, maxVisibleRules);
    const remainingRules = rulesAppliedInContentDisplay.slice(maxVisibleRules);
    const allRuleNames = rulesAppliedInContentDisplay.map(rule => rule.configRules.name).join('\n');

    return (
        <RulesComponentHolder>
            <div className="titleRuleList"> {t('Rules')}</div>
            {rulesAppliedInContentDisplay.length !== 0 ? (
                <>
                    <div
                        onClick={(event) => { event.stopPropagation(); showModalRules()}}
                        className="rulesContainer"
                        style={{ justifyContent: styleRules ? styleRules : 'flex-start', maxWidth: '300px', flexWrap: 'wrap' }}>
                        <div
                            className="nameRules"
                            style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                            <Tooltip title={allRuleNames}>
                                <span>
                                    {visibleRules.map((rule, index) => (
                                        <span
                                            key={index}
                                            style={{ display: 'inline', wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
                                        >
                                            {rule.configRules.name}
                                            {index < visibleRules.length - 1 && (
                                                <span style={{ marginRight: '5px' }}>,</span>
                                            )}
                                        </span>
                                    ))}
                                    {remainingRules.length > 0 && <span style={{ marginLeft: '5px' }}>...</span>}
                                </span>
                            </Tooltip>
                            { (listContentDisplay.length > 0 && type != "relation" ) && (
                                <span style={{ marginLeft: '5px', color: '#9E9E9E' }}>
                                    <br />
                                    { type === "display" ? t('throughout the display') : t('throughout the group') }
                                </span>
                            )}
                        </div>
                    </div>
                    {existRulesInContentDisplay &&
                        <div
                            onClick={(event) => { event.stopPropagation(); showModalRules()}}
                            className="rulesContainer nameRules"
                            style={{ justifyContent: styleRules ? styleRules : 'flex-start', maxWidth: '300px', flexWrap: 'wrap' }}>
                            <p className={!existRulesInContentDisplay ? 'textInfoRule' : 'textInfoRuleWithRules'} >
                                {t('Other applied rule(s)')}
                            </p>
                        </div>
                    }
                </>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                        onClick={(event) => { event.stopPropagation(); showModalRules()}}
                        className="nameRules"
                        style={{ justifyContent: styleRules ? styleRules : 'flex-start' }}>
                        <p className={!existRulesInContentDisplay ? 'textInfoRule' : 'textInfoRuleWithRules'} >
                            {!existRulesInContentDisplay ? defaultText : t('Rule(s) applied')}
                        </p>
                    </div>
                </div>
            )}
        </RulesComponentHolder>
    );
};

export default ListRulesComponent;