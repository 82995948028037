import styled from "styled-components";
import Colors from "../../themes/Colors";

const ScreensGroupHolder = styled.div`

  .screens-group {
    gap: 20px;
  }
  .customPanelStyleDisplays {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .colWithBorder {
    border-right: 2px solid #D9D9D9;
    height: 100%;
  }
  .horizontal-line {
    border-bottom: 1px solid #D9D9D9;
    marginTop: 10px;
    marginBottom: 15px;
  }
  .col-displays-header {
    text-align: center;
    min-height: 110px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .col-displays-header-first {
    text-align: center;
    min-height: 110px;
    align-items: center;
  }
  .col-displays {
    text-align: center;
  }
  .content-audience {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    text-align: center;
    margin-bottom: 5px;
  }

  .content-audience-relation {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
  }
  .contentRelation {
    border: 1px solid #e8e8e8;
    padding: 10px;
    padding-top: 15px;
    pagading-button: 20px;
    border-radius: 16px;
    box-shadow: 0px 0px 4px 1px #0000001A;
  }
  .tag-status-display {
    margin-left: 5px;
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    width: fit-content;;
  }
  /* Customize css for collapse header*/
  .ant-collapse-item{
    padding: 5px !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 5px !important;
  }
  .ant-collapse-header {
    color: #4C4C4C !important;
  }
  .textGroupColor {
    color: ${Colors.primary};
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 5px;
  }
  .textGroup {
    font-size: 14px;
    text-align: center;
  }
  .inputGroup {
    width: 95%;
  }
  .content-info-relation {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .input-investment {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .switch-investment {
    display: inline-flex;
    align-items: center;
    gap: 5px
  }
  .amountCampaignPerDisplay {
    display: grid;
    border: solid;
    padding-top: 4px;
    padding-bottom: 3px;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    border-left-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    padding-left: 5px;
    padding-right: 5px;
    border-color: #00000026;
    margin-right: 10px;
    margin-left: 3px;
  }

  .dividerDataDisplay {
    width: 2;
    background-color: #0000004d;
    height: 17em
  }

  .content-info {
      height: 35px;
      margin: 10px auto;
      padding: 5px;
      padding-left: 10px !important;
      padding-right: 10px !important;
      border-radius: 4px;
      background-color: #D7EAFD;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      color: #3996F3;
      display: inline;
      &.orange{
        background-color: #fff7e6;
        color: #fa8c16;
      }
  }
  .buttonActionsGroup {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .buttonActionsDisplayGroup {
    margin-right: 3px;
    margin-left: 3px;
    margin-bottom: 10px;
  }
  .screenCardAudit {
    padding: 1px 8px 1px 10px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--MAINCOLOR-600, #3996F3);
    background-color: var(--MAINCOLOR-600, #3996F3);
    margin-bottom: 5px;
    margin-top: 5px;
    color: #FFFFFF;
    font-size: 15px;
  }
  .screenCardAuditRelation {
    padding: 1px 8px 1px 10px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--MAINCOLOR-600, #3996F3);
    background-color: var(--MAINCOLOR-600, #3996F3);
    margin-bottom: 5px;
    margin-top: 5px;
    color: #FFFFFF;
    font-size: 15px;
  }
  .child {
    height: 100%;
  }
  /* Content of list Displays */
  .ant-collapse-content-box {
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: #fafafa;
  }
  .slick-next:before {
    color: #9e9e9e;
    font-size: 28px !important;
  }
  .slick-prev:before {
    right: 0px;
    color: #9e9e9e;
    font-size: 28px !important;
  }
  .content-slider-displays {
    margin-left: 6%;
    margin-right: 1%;
  }
  .grup-info-displays {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .screenContainer {
    width: 200px;
    max-width: 100%;
    margin: 5px 0;
  }
  .screenPreviewType {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .screenPreview {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
  .screenPreviewFilled {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .singleScreen {
    border-bottom: 1px solid ${Colors.primary};
    padding: 40px 0 5px;
    margin: 0 0 15px;
  }
  .screen {
    background-color: ${Colors.black};
    border: 4px solid #6f6f70;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inline-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 10px; /* Space between text and input field */
    max-width: 100%;
  }
  .inline-text {
    white-space: nowrap; /* Prevent text from wrapping to a new line */
    margin-left: 20px;
  }
  .primaryCircle {
    width: 47px;
    height: 30px;
    background-color: ${Colors.primary};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: white;
    margin-bottom: 4px;
  }
  /* Styles for the input */
  .inputGroup {
    flex: 1 1 auto; /* Allow the input to expand to fill available space */
    min-width: 0; /* Prevent the input from causing horizontal overflow */
  }
  .lastRow {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 60px;
    padding-top: 5px;
  }
  .timeTypeContent {
    margin-bottom: 10px;
    margin-top: 10px;
    color: ${Colors.snow};
    background-color: ${Colors.primary};
    border-radius: 30px;
    padding: 3px 8px;
    font-size: 12px;
    text-align: center;
  }
  `;

export default ScreensGroupHolder;